import Carousel from "./components/Carousel";
import SignUp from "./components/SignUp";

const images = [
  "https://i.imgur.com/3hGu9nN.jpeg",
  "https://i.imgur.com/PSEhKyl.jpeg",
  "https://i.imgur.com/4zJ4Cut.jpeg",
  "https://i.imgur.com/TdZEq45.jpeg",
  "https://i.imgur.com/x9iYNnL.jpeg",
  "https://i.imgur.com/V8szQ4K.jpeg"
];

function EventHero() {
  return (
    <div className="flex justify-center">
      <div
        className="flex-auto flex flex-col md:flex-row w-full relative py-12 lg:px-4 xl:px-32 flex flex-row"
        style={{
          maxWidth: "100rem"
        }}
      >
        <div className="max-w-2xl">
          <img
            className="rounded-2xl"
            alt="AlbumImage"
            src="https://i.imgur.com/IpdbTsW.jpeg"
          />
        </div>
        <div
          className="absolute leading-none font-bold text-gray-100 heroText"
          style={{
            top: "-30px"
          }}
        >
          Events
        </div>
        <div className="flex flex-col mx-12 top-1/4">
          <div className="leading-none font-thin text-gray-300 mb-6 heroSubtitle">
            <p className="text-xl max-w-xl leading-8 my-8">
              We hope to provide a platform for innovative talents in the fields
              of art, tech, sports, and media to connect, engage and create. We
              are an invitation-based membership community and give VIP access
              to owners of EZ’s NFT to participate in our biannual large scale
              events. Through this process we would like to cultivate a larger
              community supporting each other’s projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function EventPage() {
  return (
    <div>
      <div className="py-8">
        <Carousel imageUrls={images} />
      </div>
      <EventHero />
      <div className="mt-8 mb-20">
        <SignUp />
      </div>
    </div>
  );
}
