import React from "react";
import ButtonContainer from "./components/ButtonContainer";

export default function HeroContainer() {
  return (
    <div className="flex justify-center">
      <div
        className="flex-auto relative py-12 lg:px-4 xl:px-32"
        style={{
          maxWidth: "100rem"
        }}
      >
        <div className="absolute flex flex-col mx-12 top-1/4">
          <div className="leading-none font-bold text-gray-100 heroText blurText">
            IN FOCUS
          </div>
          <div className="leading-none font-thin text-gray-300 mb-6 heroSubtitle">
            EZ
          </div>
          <ButtonContainer text={"BUY NFT"} width="12rem" />
          <ButtonContainer text={"CONNECT WALLET"} width="12rem" />
        </div>
        <div className="max-w-2xl ml-auto mr-0 lg:mr-6">
          <img
            className="rounded-2xl"
            alt="AlbumImage"
            src="https://images.genius.com/fbcddb2583f836d0305d0b89b9273871.1000x1000x1.jpg"
          />
        </div>
      </div>
    </div>
  );
}
