import React from "react";

export default function VideContainer({
  link,
  className = ""
}: {
  link: string;
  className?: string;
}) {
  return (
    <iframe
      src={link}
      title="Video player"
      frameBorder="0"
      className={className}
    ></iframe>
  );
}
