import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MusicPage from "./MusicPage";
import AboutPage from "./AboutPage";
import EventPage from "./EventPage";

import App from "./App";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<MusicPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="events" element={<EventPage />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);
