export default function LyricsContainer() {
  return (
    <div
      className="lg:max-w-xl px-12 xl:px-32 my-12 overflow-y-scroll overflow-x-hidden"
      style={{
        height: "900px"
      }}
    >
      <div className="text-gray-100 font-bold heroSubtitle">LYRICS</div>
      <div className="text-gray-100 lg:text-xl pb-8">
        <p>
          [Chorus]
          <br /> Your body in focus <br /> Oh let me see you slow mo (slow
          motion)
          <br />
          Let you lose all control <br />
          In the moment <br />
          And touch you in ways you can't (imagine) <br />
          In and outta focus
        </p>
        <br />
        <p>
          [Verse 1]
          <br /> Baby keep it slow I could surf on your waves like <br />
          In go mode oh I'm ready for your games like <br />
          Step in frame, picture us together
          <br />
          For the ride, mix the pain with the (pleasure)
          <br /> You should know <br />I keep you comfortable
          <br /> Nice and slow tempo <br />
          Got exactly what you needing cuz I'm
        </p>
        <br />
        <p>
          [Bridge]
          <br />
          In focus
          <br />
          In focus
          <br />
          Info-
          <br />
          In focus
          <br />
          In focus
          <br />
          Info- (oh I'm focused)
          <br />
          In focus
          <br />
          In focus
          <br />
          Info-
          <br />
          In focus
          <br />
          In focus
          <br />
          Info-
          <br />
          <br />
          [Chorus]
          <br />
          Your body in focus
          <br />
          Oh let me see you slow mo (slow motion)
          <br />
          Let you lose all control
          <br />
          In the moment
          <br />
          And touch you in ways you can't (imagine)
          <br />
          In and outta focus
          <br />
          <br />
          [Verse 2]
          <br />
          Pressure on you
          <br />
          I could turn you on, ease it off you like
          <br />
          In a see-through
          <br />
          Dress looking better on the bedroom floor right
          <br />
          Cause you
          <br />
          Always get me real excited
          <br />
          Keep it autopilot
          <br />
          Watch the night flash by just you
          <br />
          <br />
          [Bridge]
          <br />
          In focus
          <br />
          In focus
          <br />
          Info- (oh I'm so focused)
          <br />
          In focus
          <br />
          In focus
          <br />
          Info-
          <br />
          In focus (just you)
          <br />
          In focus
          <br />
          Info-
          <br />
          In focus
          <br />
          In focus
          <br />
          Info-
          <br />
          <br />
          [Chorus]
          <br />
          Your body in focus
          <br />
          Oh let me see you slow mo (slow motion)
          <br />
          Let you lose all control
          <br />
          In the moment
          <br />
          And touch you in ways you can't (imagine)
          <br />
          In and outta focus
          <br />
        </p>
      </div>
    </div>
  );
}
