export default function ButtonContainer(props: {
  text: string;
  width: string;
}) {
  const { text, width } = props;
  return (
    <div>
      <div
        className="flex bg-purple-500 rounded justify-center text-center p-2 my-2"
        style={{
          width: width
        }}
      >
        <p className="tracking-wider text-gray-100 font-medium">{text}</p>
      </div>
    </div>
  );
}
