import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

enum NavBarLinkType {
  Music,
  About,
  Events
}

type NavBarLinkSetting = {
  link: NavBarLinkType;
  text: string;
  to: string;
};

const navLinkSettings: NavBarLinkSetting[] = [
  {
    link: NavBarLinkType.Music,
    text: "MUSIC",
    to: "/"
  },
  {
    link: NavBarLinkType.About,
    text: "ABOUT",
    to: "/about"
  },
  {
    link: NavBarLinkType.Events,
    text: "EVENTS",
    to: "/events"
  }
];

function NavBarLink({ setting }: { setting: NavBarLinkSetting }) {
  let resolved = useResolvedPath(setting.to);
  const isLinkActive = useMatch({
    path: resolved.pathname,
    end: true
  });
  let textColor = "text-gray-200 hover:text-whites";
  if (isLinkActive) {
    textColor = "text-purple-500 hover:text-purple-300";
  }
  return (
    <Link
      to={setting.to}
      className={`block mt-4 lg:inline-block lg:mt-0 ${textColor} mr-12`}
    >
      {setting.text}
    </Link>
  );
}

export default function NavBar() {
  const [activeLink, setActiveLink] = useState<NavBarLinkType>(
    NavBarLinkType.Music
  );
  return (
    <nav className="flex items-center justify-between flex-wrap bg-gray-900 py-6 lg:px-12 xl:px-40">
      <a href="/" className="flex items-center flex-shrink-0 text-white mr-6">
        <img
          className="w-36"
          alt="logo"
          src="https://i.imgur.com/mDVWxJ6.png"
        />
      </a>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-gray-200 border-teal-400 hover:text-white hover:border-white">
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-sm lg:flex-grow lg:justify-end lg:flex">
          {navLinkSettings.map((setting) => {
            return (
              <NavBarLink key={`Navbar_${setting.to}`} setting={setting} />
            );
          })}
        </div>
      </div>
    </nav>
  );
}
