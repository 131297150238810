import * as React from "react";
import InstagramLogo from "./components/InstagramLogo";
import SpotifyLogo from "./components/SpotifyLogo";

export default function Footer() {
  return (
    <footer
      className="footer relative pt-1 border-b-2"
      style={{
        backgroundColor: "#1D1C1C"
      }}
    >
      <div className="container mx-auto px-6 flex">
        <a href="/" className="mt-8">
          <img
            className="w-36"
            alt="logo"
            src="https://i.imgur.com/mDVWxJ6.png"
          />
        </a>
        <div className="sm:flex sm:mt-8 lg:w-1/2 lg:mr-0 lg:ml-auto">
          <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
            <div className="flex flex-col">
              <span className="font-bold text-gray-200 uppercase mt-4 md:mt-0 mb-2">
                INFORMATION
              </span>
              <span className="my-2">
                <a
                  href="/"
                  className="text-gray-200 text-md hover:text-blue-500"
                >
                  Music
                </a>
              </span>
              <span className="my-2">
                <a
                  href="/about"
                  className="text-gray-200  text-md hover:text-blue-500"
                >
                  About
                </a>
              </span>
              <span className="my-2">
                <a
                  href="/events"
                  className="text-gray-200 text-md hover:text-blue-500"
                >
                  Events
                </a>
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-200 uppercase mt-4 md:mt-0 mb-2">
                Social Media
              </span>
              <div className="flex flex-row">
                <span className="m-2">
                  <a
                    href="https://www.instagram.com/_ezhk_/"
                    className="text-gray-200  text-md hover:text-blue-500"
                  >
                    <InstagramLogo color="white" />
                  </a>
                </span>
                <span className="m-2">
                  <a
                    href="https://open.spotify.com/artist/08u7HS5kVzgJ4VEslMB8jS?si=xjTG6zslSgCTOleiA2m1Xw"
                    className="text-gray-200  text-md hover:text-blue-500"
                  >
                    <SpotifyLogo color="white" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-6">
        <div className="mt-16 border-t-2 border-gray-800 flex flex-col items-center">
          <div className="sm:w-2/3 text-center py-6">
            <p className="text-sm text-gray-600 font-bold mb-2">
              © 2021 All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
