export default function SignUp() {
  return (
    <div className="flex justify-center">
      <div
        className="flex-auto relative signupContainer"
        style={{
          maxWidth: "100rem"
        }}
      >
        <div className="absolute w-full bottom-0">
          <form action="#">
            <div className="max-w-sm mx-auto p-1 pr-0 flex items-center">
              <input
                type="email"
                placeholder="youremail@example.com"
                className="flex-1 bg-gray-900 border-b-2 border-gray-600 focus:border-gray-200 appearance-none rounded p-3 text-grey-100 mr-2 focus:outline-none"
                style={{
                  color: "white"
                }}
              />
              <button
                type="submit"
                className="bg-blue-600 text-white text-base font-semibold rounded-md shadow-md hover:bg-indigo-600 p-3"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div className="absolute signupText text-purple-600 font-bold">
          Sign up
        </div>
      </div>
    </div>
  );
}
