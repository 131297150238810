import Slider from "react-slick";
import useWindowSize from "../UseWindowSize";

const MD_WIDTH = 1280;

export default function Carousel({ imageUrls }: { imageUrls: string[] }) {
  const imgClassName = "rounded-2xl";

  const [width, height] = useWindowSize();

  const isMdScreen = width > MD_WIDTH;

  const settings = {
    dots: true,
    arrows: isMdScreen,
    infinite: true,
    speed: 500,
    slidesToShow: isMdScreen ? 2 : 1,
    centerMode: true,
    swipeToSlide: true
  };

  return (
    <Slider {...settings}>
      {imageUrls.map((url) => {
        return (
          <div key={`${url}_key`} className="px-4 lg:px-8">
            <img alt="carousel" className={imgClassName} src={url} />
          </div>
        );
      })}
    </Slider>
  );
}
