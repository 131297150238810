import * as React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
export default function App() {
  return (
    <div className="App w-full h-full bg-gray-900 overflow-hidden">
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}
