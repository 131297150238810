import HeroContainer from "./HeroContainer";
import VideoContainer from "./components/VideoContainer";
import LyricsContainer from "./LyricsContainer";

export default function MusicPage() {
  return (
    <>
      <HeroContainer />
      <div className="flex justify-center">
        <div className="flex-auto max-w-7xl">
          <div
            className="relative"
            style={{
              paddingTop: "56.25%"
            }}
          >
            <VideoContainer
              className="absolute top-0 left-0 w-full h-full rounded-lg"
              link="https://player.vimeo.com/video/655733121?h=8b0079e841"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center">
        <div className="relative">
          <img
            className="max-w-2xl rounded-lg"
            alt="lyrics"
            src="https://i.imgur.com/eVohAza.jpeg"
          />
        </div>
        <LyricsContainer />
      </div>
    </>
  );
}
