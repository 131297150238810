import React from "react";
import Carousel from "./components/Carousel";

function AboutHero() {
  return (
    <div className="flex justify-center">
      <div
        className="flex-auto relative py-12 lg:px-4 xl:px-32 flex flex-row"
        style={{
          maxWidth: "100rem"
        }}
      >
        <div className="flex flex-col mx-12 top-1/4">
          <div className="leading-none font-bold text-gray-100 heroText">
            EZ
          </div>
          <div className="leading-none font-thin text-gray-300 mb-6 heroSubtitle">
            <p className="text-xl max-w-xl leading-8 my-8">
              EZ is a singer, rapper, producer based in Hong Kong. His primary
              music genre is R&B / Hip Hop, with main influences including
              @partyomo, @brysontiller, and @jay-park-official. "IN FOCUS" is
              the first single release off of the upcoming debut R&B/Hip Hop
              project “VIBRATIONS”(Release Date TBD).
            </p>
          </div>
        </div>
        <div
          className="max-w-2xl rounded-2xl overflow-hidden"
          style={{
            maxHeight: "800px"
          }}
        >
          <img
            className="w-full"
            alt="AlbumImage"
            src="https://i.imgur.com/f3z1Skw.jpeg"
          />
        </div>
      </div>
    </div>
  );
}

const images = [
  "https://i.imgur.com/aUfRClC.jpeg",
  "https://i.imgur.com/GROOinl.jpeg",
  "https://i.imgur.com/rrO5n7w.jpeg",
  "https://i.imgur.com/6HSqjTt.jpeg"
];

const MD_WIDTH = 1280;

export default function AboutPage() {
  return (
    <div>
      <AboutHero />
      <div className="py-8">
        <Carousel imageUrls={images} />
      </div>
    </div>
  );
}
